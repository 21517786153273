<template>
  <div>
    <section>
      <TopBar v-bind="topBarProp" @itemClick="topBarClick" />
    </section>
    <section id="home">
      <Parallax v-bind="parallaxProp" />
    </section>
    <section id="message">
      <Card v-bind="firstCardProp" />
    </section>
    <section id="vision">
      <Card v-bind="secondCardProp">
        <template v-slot:text>
          <p class="black--text">AppSociallyでは全メンバーが以下の基準を満たすよう努力しています。</p>
          <ul>
            <li class="black--text">
              <p>人々がそれを望んでいるか?</p>
            </li>
            <li class="black--text">
              <p>私たち自身がそれを心から楽しんでいるか??</p>
            </li>
            <li class="black--text">
              <p>私たちにはそれを実行する能力があるか?</p>
            </li>
            <li class="black--text">
              <p>それは拡大および繰返可能なビジネスモデルか?</p>
            </li>
            <li class="black--text">
              <p>それは革新的か?</p>
            </li>
            <li class="black--text">
              <p>それは破壊的か（新しい視点で業界を再構築できるか）?</p>
            </li>
            <li class="black--text">
              <p>それは感動的に美しいか?</p>
            </li>
          </ul>
        </template>
      </Card>
    </section>
    <section id="listings">
      <Cards v-bind="positionProp" />
    </section>
    <section id="chat">
      <Card v-bind="chatProp">
        <template v-slot:text>
          <div class="d-flex align-center flex-column justify-center">
            <v-img :src="chatImage" width="300" />
            <p class="black--text">
              チャットで24時間応募受付中です。
              <br />まずは一度お話ししましょう！
            </p>
          </div>
        </template>
        <template v-slot:actions>
          <v-btn outlined color="black" x-large>チャットで簡単応募</v-btn>
        </template>
      </Card>
    </section>
  </div>
</template>

<script>
import Parallax from './parallax-2col'
import Card from './parallax-card'
import Cards from './parallax-cards'
import TopBar from './topbar'

import parallaxImage from '@/assets/site/demo/main.webp'
import loungeImage from '@/assets/site/demo/lounge.webp'
import chatImage from '@/assets/site/demo/speech.png'
import logo from '@/assets/site/demo/logo_appsocially.webp'

export default {
  components: {
    Parallax,
    Card,
    Cards,
    TopBar
  },
  data () {
    return {
      parallaxProp: {
        image: parallaxImage,
        firstText: '私たちと一緒に',
        secondText: '働きませんか？',
        color: 'grey darken-4'
      },
      firstCardProp: {
        title: {
          text: 'AppSociallyについて',
          color: 'black--text'
        },
        body: {
          text: 'シリコンバレーで創業し、500 Startupsからの投資により第一歩を踏み出したAppSocially。私達の基盤には、シリコンバレーにおける、世界中の優れた起業家が集まり、科学的なアプローチで顧客と製品を開発しながら、切磋琢磨するカルチャーがあります。私たちは、プロダクト、データ、デザインの力で、世界を、より幸せで、より健康で、より生産的で、より人と人のつながりを感じることのできる場所にすることをミッションに掲げています。人類、世界の進歩のために一緒に奮励したい方、ご連絡をお待ちしてます！',
          color: 'white--text'
        },
        text: `シリコンバレーで創業し、500 Startupsからの投資により第一歩を踏み出したAppSocially。私達の基盤には、シリコンバレーにおける、世界中の優れた起業家が集まり、科学的なアプローチで顧客と製品を開発しながら、切磋琢磨するカルチャーがあります。私たちは、プロダクト、データ、デザインの力で、世界を、より幸せで、より健康で、より生産的で、より人と人のつながりを感じることのできる場所にすることをミッションに掲げています。人類、世界の進歩のために一緒に奮励したい方、ご連絡をお待ちしてます！`,
        color: 'white',
        cardColor: 'grey darken-4',
        colSize: 7,
        height: 735
      },
      secondCardProp: {
        image: loungeImage,
        title: {
          text: '働き方',
          color: 'black--text'
        },
        cardColor: 'white',
        colSize: 6,
        height: 735
      },
      positionProp: {
        title: {
          text: '募集職種',
          color: 'white--text'
        },
        color: 'grey darken-4',
        height: 1400,
        items: [
          {
            title: {
              text: 'フロントエンド',
              color: 'black--text'
            },
            subTitle: {
              text: 'エンジニア',
              color: 'black--text'
            },
            button: {
              text: '詳しく見る',
              color: 'white--text',
              backgroundColor: 'red'
            }
          },
          {
            title: {
              text: 'データ',
              color: 'black--text'
            },
            subTitle: {
              text: 'サイエンティスト',
              color: 'black--text'
            },
            button: {
              text: '詳しく見る',
              color: 'white--text',
              backgroundColor: 'red'
            }
          },
          {
            title: {
              text: 'プロダクト',
              color: 'black--text'
            },
            subTitle: {
              text: '​マネジャー',
              color: 'black--text'
            },
            button: {
              text: '詳しく見る',
              color: 'white--text',
              backgroundColor: 'red'
            }
          },
          {
            title: {
              text: 'UI/UX',
              color: 'black--text'
            },
            subTitle: {
              text: '​デザイナー',
              color: 'black--text'
            },
            button: {
              text: '詳しく見る',
              color: 'white--text',
              backgroundColor: 'red'
            }
          },
          {
            title: {
              text: 'ソリューション',
              color: 'black--text'
            },
            subTitle: {
              text: 'セールス',
              color: 'black--text'
            },
            button: {
              text: '詳しく見る',
              color: 'white--text',
              backgroundColor: 'red'
            }
          },
          {
            title: {
              text: 'AIシナリオ',
              color: 'black--text'
            },
            subTitle: {
              text: 'ライター',
              color: 'black--text'
            },
            button: {
              text: '詳しく見る',
              color: 'white--text',
              backgroundColor: 'red'
            }
          },
          {
            title: {
              text: 'データ',
              color: 'black--text'
            },
            subTitle: {
              text: 'サイエンティスト',
              color: 'black--text'
            },
            button: {
              text: '詳しく見る',
              color: 'white--text',
              backgroundColor: 'red'
            }
          },
          {
            title: {
              text: 'プロダクト',
              color: 'black--text'
            },
            subTitle: {
              text: 'マネジャー',
              color: 'black--text'
            },
            button: {
              text: '詳しく見る',
              color: 'white--text',
              backgroundColor: 'red'
            }
          }],
        cardColor: 'white'
      },
      chatProp: {
        title: {
          text: '一緒に働いてみませんか？',
          color: 'black--text'
        },
        cardColor: 'white',
        colSize: 8,
        height: 735,
        color: 'white'
      },
      topBarProp: {
        logo,
        items: [
          { text: 'ホーム', value: 'home' },
          { text: 'メッセージ', value: 'message' },
          { text: '働き方について', value: 'vision' },
          { text: '募集職種', value: 'listings' },
          { text: '応募する', value: 'chat' }
        ],
        color: 'grey darken-4'
      }
    }
  },
  computed: {
    chatImage () { return chatImage },
    logo () { return logo }
  },
  methods: {
    topBarClick (value) {
      this.$vuetify.goTo(`#${value}`)
    }
  },
  async mounted () {
  }
}
</script>

<style>
</style>
