<template>
  <v-parallax :height="height" :src="image">
    <v-layout>
      <v-row class="fill-height" align-content="center" justify="center" :class="color">
        <v-col :cols="12" :xs="colSize" :md="colSize" class="pa-0">
          <p class="text-center display-2" :class="title.color">{{title.text}}</p>
          <v-card flat class="py-8 px-10" :color="cardColor">
            <v-card-title>
              <slot name="title"></slot>
            </v-card-title>
            <v-card-text class="d-flex-inline align-center justify-center">
              <slot name="text">
                <p :class="body.color">{{body.text}}</p>
              </slot>
            </v-card-text>
            <v-card-actions class="d-flex-inline align-center justify-center">
              <slot name="actions"></slot>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-parallax>
</template>

<script>
export default {
  props: {
    image: { default: () => { return '' } },
    title: {
      default: () => {
        return {
          text: '',
          color: 'black--text'
        }
      }
    },
    body: {
      default: () => {
        return {
          text: '',
          color: 'black--text'
        }
      }
    },
    color: { default: () => { return '' } },
    cardColor: { default: () => { return 'gray' } },
    colSize: { default: () => { return 6 } },
    height: { default: () => { return 735 } }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  async mounted () {
  }
}
</script>

<style>
</style>
