<template>
  <v-sheet :height="height" :color="color" class="d-flex align-center justify-center">
    <v-img :max-height="64" contain :src="logo" />
    <v-btn
      text
      v-for="({ text, value }, index) in items"
      @click="click(value)"
      v-bind:key="index"
    >{{text}}</v-btn>
  </v-sheet>
</template>

<script>
export default {
  props: {
    logo: { default: () => { return '' } },
    items: { default: () => {
      return [
        { text: 'home', value: 'home' }
      ]
    } },
    height: { default: () => { return 128 } },
    color: { default: () => { return '' } }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    click (value) {
      this.$emit('itemClick', value)
    }
  },
  async mounted () {
  }
}
</script>

<style>
</style>
