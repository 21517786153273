<template>
  <Site />
</template>

<script>
import Site from '@/components/site'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'

export default {
  components: {
    Site
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_DEMO_SITE)
  }
}
</script>
