<template>
  <v-layout>
    <v-row class="fill-height" v-bind:class="color">
      <v-col :cols="6" class="pa-0">
        <v-parallax :src="image"></v-parallax>
      </v-col>
      <v-col :cols="6" align-self="center">
        <p class="text-center display-2">{{firstText}}</p>
        <p class="text-center display-2">{{secondText}}</p>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  props: {
    image: { default: () => { return '' } },
    firstText: { default: () => { return '' } },
    secondText: { default: () => { return '' } },
    color: { default: () => { return 'gray' } }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  async mounted () {
  }
}
</script>

<style>
</style>
