<template>
  <v-parallax :height="height" :src="image">
    <v-layout>
      <v-row class="fill-height" align-content="center" justify="center" :class="color">
        <v-col :cols="12">
          <p class="text-center display-2" :class="title.color">{{title.text}}</p>
        </v-col>
        <v-col :cols="6" v-for="({ title, subTitle, button }, index) in items" v-bind:key="index">
          <v-card flat class="py-8 px-10" :color="cardColor">
            <p class="text-center headline font-weight-bold" :class="title.color">{{title.text}}</p>
            <p
              class="text-center headline font-weight-bold"
              :class="subTitle.color"
            >{{subTitle.text}}</p>

            <v-card-actions class="d-flex justify-center">
              <v-btn
                class="px-8"
                :color="button.backgroundColor"
                large
                :v-class="button.color"
              >{{button.text}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-layout>
  </v-parallax>
</template>

<script>
export default {
  props: {
    image: { default: () => { return '' } },
    title: {
      text: '',
      color: 'black--text'
    },
    items: {
      default: () => {
        return [{
          title: {
            text: '',
            color: 'black--text'
          },
          subTitle: {
            text: '',
            color: 'black--text'
          },
          button: {
            text: '',
            color: 'white--text',
            backgroundColor: 'black'
          }
        }]
      }
    },
    color: { default: () => { return '' } },
    cardColor: { default: () => { return 'gray' } },
    height: { default: () => { return 735 } }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
  async mounted () {
  }
}
</script>

<style>
</style>
